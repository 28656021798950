import { Avatar, Tooltip, TooltipProps } from '@mantine/core';
import { AnalysisType } from '@models/ProjectAnalysisModel/ProjectAnalysisModelBase';
import { IconTimeline } from '@tabler/icons';

import { IconChartArrows, IconMathFunction, IconSpeakerphone } from '@tabler/icons-react';
import { FC } from 'react';

interface AnalysisTypeIconProps {
  type: AnalysisType;
  tooltipProps?: Omit<TooltipProps, "label" | "children">
}

export const analysisTypeToString = (type: AnalysisType) => {
  switch(type) {
    case AnalysisType.DRIVERS_OF_OUTCOME: return "Key Drivers of Outcome in Population"
    case AnalysisType.INTERNAL_BENCHMARK: return "Benchmark"
    case AnalysisType.RAW_VOICE: return "Raw Voice"
    case AnalysisType.PROGRESS_OVER_TIME: return "Progress Over Time"
  }
}

const AnalysisTypeIcon: FC<AnalysisTypeIconProps> = ({ type, tooltipProps }) => {
  switch (type) {
    case AnalysisType.DRIVERS_OF_OUTCOME:
      return (
        <Tooltip label={analysisTypeToString(type)} {...tooltipProps}>
          <div style={{ width: 18, height: 18 }}>
            <IconMathFunction size={18} />
          </div>
        </Tooltip>
      );
    case AnalysisType.INTERNAL_BENCHMARK:
      return (
        <Tooltip label={analysisTypeToString(type)} {...tooltipProps}>
          <div style={{ width: 18, height: 18 }}>
            <IconChartArrows size={18} />
          </div>
        </Tooltip>
      );
    case AnalysisType.RAW_VOICE:
        return (
          <Tooltip label={analysisTypeToString(type)} {...tooltipProps}>
            <div style={{ width: 18, height: 18 }}>
              <IconSpeakerphone size={18} />
            </div>
          </Tooltip>
        );
    case AnalysisType.PROGRESS_OVER_TIME:
      return (
        <Tooltip label={analysisTypeToString(type)} {...tooltipProps}>
          <div style={{ width: 18, height: 18 }}>
            <IconTimeline size={18} />
          </div>
        </Tooltip>
      );
  }
};

export default AnalysisTypeIcon;
