import Link from '@components/Link';
import { useCurrentProject } from '@hooks/useCurrentProject';
import {
  Box,
  Group,
  Stack,
  Title,
  Text,
  Modal,
  Card,
} from '@mantine/core';
import { useHover } from '@mantine/hooks';
import { IconTimeline } from '@tabler/icons';
import { IconChartArrows, IconMathFunction, IconSpeakerphone } from '@tabler/icons-react';
import React, { ReactNode } from 'react';

interface NewAnalysisModalProps {
  opened: boolean;
  onClose: () => void;
}

export const NewAnalysisModal: React.FC<NewAnalysisModalProps> = ({
  opened,
  onClose,
}) => {
  const project = useCurrentProject();

  if (!project) {
    return null;
  }

  return (
    <Modal
      opened={opened}
      onClose={onClose}
      title={
        <Stack spacing={1}>
          <Title order={3}>New Analysis</Title>
          <Text size="sm" color="dimmed">
            Select the type of analysis you would like to create
          </Text>
        </Stack>
      }
      size="xl"
    >
      <Stack spacing="md" mt="md">
        <ModalItem
          title="Benchmark"
          text="See how a focal population of interest compares to comparable segments within the organization. Example: Comparing all business units to each other."
          link={`/projects/${project.projectId}/new-benchmark`}
          icon={<IconChartArrows size={20} />}
        />
        <ModalItem
          title="Key Drivers of Outcome in Population"
          text="Predict an outcome of interest within a population of your choosing. Example: Understanding the main drivers of innovation in high performing engineers."
          link={`/projects/${project.projectId}/new-key-drivers`}
          icon={<IconMathFunction size={20} />}
        />
        <ModalItem
          title="Raw Voice"
          text="Measure raw sentiment and incidence for a population."
          link={`/projects/${project.projectId}/new-raw-voice`}
          icon={<IconSpeakerphone size={20} />}
        />
        <ModalItem
          title="Progress over Time"
          text="Measure changes over important time periods. Example: Understanding how employee engagement changes over time."
          link={`/projects/${project.projectId}/new-progress-over-time`}
          icon={<IconTimeline size={20} />}
        />
      </Stack>
    </Modal>
  );
};

interface ModalItemProps {
  title: string;
  text: string;
  link: string;
  icon: ReactNode;
}

const ModalItem: React.FC<ModalItemProps> = ({ title, text, link, icon }) => {
  const { hovered, ref } = useHover();
  return (
    <div ref={ref}>
      <Card
        withBorder
        component={Link}
        to={link}
        style={{ background: hovered ? '#efefef' : 'white' }}
      >
        <Stack spacing={'md'}>
          <Group align="center" spacing="xs">
            {icon}
            <Title size="h6">{title}</Title>
          </Group>
          <Box style={{ maxWidth: '100%' }}>
            <Text color="dimmed" size="sm" style={{ whiteSpace: 'normal' }}>
              {text}
            </Text>
          </Box>
        </Stack>
      </Card>
    </div>
  );
};
