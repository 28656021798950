import { FC, useEffect } from 'react';
import { ChartType, useAnalysisStore } from '@stores/AnalysisStore';
import { SegmentedControl } from '@mantine/core';
import { AnalysisType } from '@models/ProjectAnalysisModel/ProjectAnalysisModelBase';
import { useCurrentAnalysis } from '@hooks/useCurrentAnalysis';

const internalBenchmarkOptions = [
  {
    label: 'POPULATION VS. BENCHMARK',
    value: ChartType.POPULATION_AGAINST_BENCHMARK,
  },
  { label: 'BREAKDOWN BY TOPIC', value: ChartType.BREAKDOWN_BY_TOPIC },
  {
    label: 'BREAKDOWN BY POPULATION',
    value: ChartType.BREAKDOWN_BY_POPULATION,
  },
  { label: 'IN SAME BREATH', value: ChartType.IN_SAME_BREATH },
  { label: 'SUB CULTURES', value: ChartType.SUB_CULTURES },
];

const rawVoiceOptions = [
  {
    label: 'RAW VOICE',
    value: ChartType.RAW_VOICE,
  },
  { label: 'BREAKDOWN BY TOPIC', value: ChartType.BREAKDOWN_BY_TOPIC },
  { label: 'IN SAME BREATH', value: ChartType.IN_SAME_BREATH },
  { label: 'SUB CULTURES', value: ChartType.SUB_CULTURES },
];

const driverOfOutcomeOptions = [
  { label: 'OVERVIEW OF IMPORTANCE', value: ChartType.OVERVIEW_OF_IMPORTANCE },
  { label: 'DRIVES OUTCOME', value: ChartType.DRIVES_OUTCOME },
  { label: 'BREAKDOWN BY TOPIC', value: ChartType.BREAKDOWN_BY_TOPIC },
  { label: 'IN SAME BREATH', value: ChartType.IN_SAME_BREATH },
  { label: 'SUB CULTURES', value: ChartType.SUB_CULTURES },
];

const progressOverTimeOptions = [
  { label: "PROGRESS OVER TIME", value: ChartType.PROGRESS_OVER_TIME },
  { label: "BREAKDOWN BY TOPIC", value: ChartType.BREAKDOWN_BY_TOPIC },
  {
    label: 'BREAKDOWN BY POPULATION',
    value: ChartType.BREAKDOWN_BY_POPULATION,
  },
  { label: "IN SAME BREATH", value: ChartType.IN_SAME_BREATH },
  { label: "SUB CULTURES", value: ChartType.SUB_CULTURES }
];

const ChartSelector: FC = () => {
  const currentAnalysis = useCurrentAnalysis();
  const selectedChart = useAnalysisStore((s) => s.selectedChart);
  const setSelectedChart = useAnalysisStore((s) => s.setSelectedChart);

  useEffect(() => {
    switch (currentAnalysis.type) {
      case AnalysisType.INTERNAL_BENCHMARK:
        if(internalBenchmarkOptions.find(o => o.value === selectedChart) === undefined) {
          setSelectedChart(ChartType.POPULATION_AGAINST_BENCHMARK);
        }
        break;
      case AnalysisType.RAW_VOICE:
        if(rawVoiceOptions.find(o => o.value === selectedChart) === undefined) {
          setSelectedChart(ChartType.RAW_VOICE);
        }
        break;
      case AnalysisType.DRIVERS_OF_OUTCOME:
        if(driverOfOutcomeOptions.find(o => o.value === selectedChart) === undefined) {
          setSelectedChart(ChartType.OVERVIEW_OF_IMPORTANCE);
        }
        break;
      case AnalysisType.PROGRESS_OVER_TIME:
        if(progressOverTimeOptions.find(o => o.value === selectedChart) === undefined) {
          setSelectedChart(ChartType.PROGRESS_OVER_TIME);
        }
        break;
    }
  }, [currentAnalysis, selectedChart])

  let options = [];
  switch (currentAnalysis.type) {
    case AnalysisType.INTERNAL_BENCHMARK:
      options = internalBenchmarkOptions;
      break;
    case AnalysisType.RAW_VOICE:
      options = rawVoiceOptions;
      break;
    case AnalysisType.DRIVERS_OF_OUTCOME:
      options = driverOfOutcomeOptions;
      break;
    case AnalysisType.PROGRESS_OVER_TIME:
      options = progressOverTimeOptions;
      break;
    default:
      return null;
  }

  return (
    <SegmentedControl
      fullWidth
      size="sm"
      color="blue"
      data={options}
      value={selectedChart}
      onChange={(value: ChartType) => setSelectedChart(value)}
    />
  );
};

export default ChartSelector;
