import React, { useEffect, useMemo, useState } from 'react';
import * as echarts from 'echarts';
import {
  ShapDriversDataPoint,
} from './datamodels/ShapDriversDataPoint';
import { Center, useMantineTheme } from '@mantine/core';
import darkTheme from './themes/dark-theme.json';
import { useAnalysisStore } from '@stores/AnalysisStore';
import { useCurrentProject } from '@hooks/useCurrentProject';
import APIErrorMessage from '@components/APIErrorMessage';
import { useCurrentAnalysis } from '@hooks/useCurrentAnalysis';
import { useDriversData } from '@apis/hooks/useDriversData';
import { useTopicStore } from '@stores/TopicStore';
import { useCurrentSummary } from '@hooks/useCurrentSummary';
import { useSummaryData } from '@apis/hooks/useSummaryData';
import { useElementSize } from '@mantine/hooks';

echarts.registerTheme('dark', darkTheme);

/**
 * Shap Drivers Power Bar Plot is a simple bar plot designed to show the breakdown of drivers by feature, with a limit on the number of values shown. Will also include a tooltip for nX times more powerful than average driver.
 * @param props
 * @returns
 */
const ShapDriversPowerBarPlot: React.FC = () => {
  const theme = useMantineTheme();

  // Analysis Store
  const focalPopulation = useAnalysisStore((s) => s.focalPopulation);
  const aggregationLevel = useAnalysisStore((s) => s.activeModelAggregation);

  // Topic Store
  const focalTopic = useTopicStore((s) => s.focalTopic);
  const _setFocalTopic = useTopicStore((s) => s.setFocalTopic);
  const setFocalTopic = (topic: string) => {
    // If the topic has _, split and make eeach word capitalized, then rejoin with _
    const splitTopic = topic.split('_');
    const capitalizedTopic = splitTopic
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join('_');
    _setFocalTopic(capitalizedTopic);
  };

  const [ chartInstance, setChartInstance ] = useState<echarts.EChartsType | undefined>();
  const { ref: referenceComponent, width, height } = useElementSize();
  useEffect(() => chartInstance?.resize(), [width, height])
  const project = useCurrentProject();
  const currentAnalysis = useCurrentAnalysis();
  const currentSummary = useCurrentSummary();
  const { data: _summaryData } = useSummaryData(currentSummary);
  const summaryData = useMemo(() => {
    const analisys = (currentSummary?.analyses ?? []).find(a => a.id === currentAnalysis.id);
    if(!currentSummary || !analisys || analisys.focalPopulation !== focalPopulation) {
      return null
    }
    return _summaryData;
  }, [currentAnalysis?.id, currentSummary, focalPopulation,  _summaryData])

  const {
    data: shapData,
    isLoading: dataIsLoading,
    error: driversDataError,
    isError: isDriversDataError,
  } = useDriversData(project, currentAnalysis, aggregationLevel);

  const dataIsEmpty = shapData?.length === 0;

  const usableData: ShapDriversDataPoint[] = useMemo(() => {
    if (shapData === undefined) return [];

    return shapData
      .filter((dataPoint) => dataPoint.featureInModel === true)
      .sort((a, b) => a.combinedShap - b.combinedShap);
  }, [shapData]);

  const averageCombinedShap = useMemo(() => {
    return (
      (shapData ?? []).reduce((acc, dataPoint) => acc + dataPoint.combinedShap, 0) /
      (shapData ?? []).length
    );
  }, [shapData]);

  var selectedRangeDatapoints: ShapDriversDataPoint[] = [];

  useEffect(() => {
    const chartElement = document.getElementById('myChart');
    if (!chartElement) {
      return;
    }

    const chart = echarts.init(chartElement, theme.colorScheme);

    if (dataIsLoading === true) {
      chart.showLoading('default', {
        text: 'Loading...',
        fontSize: 16,
        color: '#4589df',
        textColor: '#000',
        zlevel: 0,
      });
    } else {
      chart.hideLoading();
    }

    if (dataIsEmpty && !dataIsLoading) {
      chart.setOption({
        title: {
          text: 'No Data Available',
          subtext:
            'This may be due to a filter being applied, or because the data is not available yet',
          top: 'center',
          textStyle: {
            fontSize: 20,
          },
          subtextStyle: {
            fontSize: 16,
          },
        },
      });
    } else {
      const option: any = {
        xAxis: {
          type: 'value',
          name: 'Relative predictive power of topics (predictive power of topic divided by predictive power of average topic)',
          nameTextStyle: {
            fontWeight: 'bold',
          },
          nameGap: 40,
          nameLocation: 'middle',
          axisLabel: {
            color: 'black',
            formatter: (value: number) => {
              return Math.floor(value) + '%';
            },
          },
        },
        yAxis: {
          type: 'category',
          data: usableData.map(
            (d: ShapDriversDataPoint) => d.featureUserFriendlyName
          ),
          nameTextStyle: {
            fontWeight: 'bold',
          },
          nameGap: 40,
          nameLocation: 'middle',
          axisLabel: {
            color: 'black',
            // Truncate the label to 24 characters
            formatter: v => v.length > 24 ? v.substr(0, 24) + '...' : v,
          },
        },
        series: [
          {
            data: usableData.map(
              (d: ShapDriversDataPoint) => d.combinedShap * 100
            ),
            type: 'bar',
            itemStyle: {
              color: function (params) {
                const dataIndex = params.dataIndex;

                const dataPoint = usableData[dataIndex];
                if (
                  focalTopic &&
                  focalTopic.toLowerCase() === dataPoint.feature.toLowerCase()
                ) {
                  return 'rgb(227, 66, 54)';
                }

                return 'rgb(90,111,192)';
              },
              borderRadius: [0, 5, 5, 0],
            },
            label: {
              show: true,
              position: 'right',
              fontSize: 14,
              formatter: (params: any) => {
                const dataPoint = params.data / 100; // Remove the multiplier
                const multiplier = dataPoint / averageCombinedShap;
                // Remove all decimals...
                const formattedMultiplier = multiplier.toFixed(1);
                return formattedMultiplier + 'x';
              },
            },
          },
        ],

        tooltip: {
          trigger: 'item',
          formatter: (params: any) => {
            // Format the tooltip to have the following:
            // 1. The name of the population
            // 2. The percentage of employees mentioning the population
            // 3. The percentage of mentions favorable

            // Get the data
            const dataIndex: number = params.dataIndex;
            const formattedPoint: ShapDriversDataPoint = usableData[dataIndex];

            let summaryText = ""
            const summaryTopicData = (summaryData ?? []).find(d => d.topic === formattedPoint.feature.toLowerCase())
            if(summaryTopicData) {
              summaryText = `
              <div>
                  <br/>
                  <div><b>Values in component analyses</b></div>
                  ${
                    Object.keys(summaryTopicData).map(k => {
                      if(k !== "topic" && k !== "prioritize") {
                        const result = `<div>${(summaryTopicData[k] as any).name}: <b>${(summaryTopicData[k] as any).value?.toFixed(1)}</b></div>`
                        if(k === currentAnalysis?.id) {
                          return `<b>${result}</b>`
                        }
                        return result
                      }
                      return null
                    }).filter(a => a !== null).join("")
                  }
              </div>`
            }

            // Return as a HTML box with the data inside
            return `
                        <div>
                            <div><b>${
                              formattedPoint.featureUserFriendlyName
                            }</b></div>
                            <div>Population: <b>${
                              formattedPoint.population
                            }</b></div
                            <div># Records: <b>${formattedPoint.populationCount.toLocaleString()}</b></div>
                        </div>
                    ` + summaryText;
          },
        },

        brush: {
          toolbox: ['rect', 'clear'],
          transformable: false,
          saveAsImage: {},
          inBrush: {
            opacity: 1,
            borderRadius: 3,
            borderColor: 'black',
          },
          outOfBrush: {
            color: 'gray',
            opacity: 0.2,
          },
        },

        toolbox: {
          show: true,
          feature: {
            saveAsImage: {
              pixelRatio: 4,
            },
          },
        },

        title: [
          {
            text: '% Outcome explained by topic',
            x: 'center',
            top: 10,
            textStyle: {
              fontSize: 16,
            },
          },
        ],

        interactive: true,
        animationEasing: 'elasticOut',
        animationDelay: 50,
        dataZoom: [
          {
            type: 'inside',
            id: 'insideY',
            yAxisIndex: 0, // This targets the yAxis for vertical scrolling
            filterMode: 'none',
            zoomLock: false,
            start: 100,
            end: 100 - (15 / usableData.length) * 100,
            preventDefaultMouseMove: false,
            zoomOnMouseWheel: false,
            moveOnMouseMove: true,
            moveOnMouseWheel: true,
          },
        ],

        grid: {
          left: '15%',
        },
      };

      // Get the chart element
      chart.setOption(option);

      // Add an onClick event to the chart...
      chart.on('click', (params: any) => {
        if (params.componentType === 'series') {
          const x = params.event.offsetX;
          const y = params.event.offsetY;

          const selectedPoint = params.value;
          const dataIndex = params.dataIndex;

          const formattedSelectedPoint: ShapDriversDataPoint =
            usableData[dataIndex];

          // Set the focal topic to the selected topic
          setFocalTopic(formattedSelectedPoint.feature);
        }
      });

      chart.on('brushSelected', (params: any) => {
        if (params.batch === null) {
          return;
        }

        var selectedDatapoints: ShapDriversDataPoint[] = [];
        var brushComponent = params.batch[0];
        for (var sIdx = 0; sIdx < brushComponent.selected.length; sIdx++) {
          var dataIndexes = brushComponent.selected[sIdx].dataIndex;
          dataIndexes.forEach((dataIndex: number) => {
            var dataPoint = usableData[dataIndex];
            selectedDatapoints.push(dataPoint);
          });
        }
        selectedRangeDatapoints = selectedDatapoints;
      });
    }

    setChartInstance(chart);

    return () => {
      setChartInstance(undefined);
      chart.dispose();
    };
  }, [shapData, focalTopic, theme.colorScheme]);

  if (dataIsLoading === false && isDriversDataError && driversDataError) {
    return (
      <Center style={{ height: '100%' }}>
        <APIErrorMessage response={driversDataError} />
      </Center>
    );
  }

  return <div ref={referenceComponent} id="myChart" style={{ width: '100%', height: '100%' }}></div>;
};

export default ShapDriversPowerBarPlot;
