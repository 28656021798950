import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  ActionIcon,
  Anchor,
  Badge,
  Button,
  Card,
  Checkbox,
  Flex,
  Group,
  MantineProvider,
  Menu,
  Modal,
  ScrollArea,
  Stack,
  Text,
  TextInput,
  Title,
  Tooltip,
} from '@mantine/core';
import { IconSearch, IconX } from '@tabler/icons';
import {
  AnalysisType,
  Population,
  useAnalysisEditionStore,
} from '@stores/AnalysisEditionStore';
import { IconDotsVertical, IconEdit, IconTrash } from '@tabler/icons-react';
import { FocalPopulationView } from '@components/FocalPopulationView';
import { useProjectDatasets } from '@apis/hooks/useProjectDatasets';
import { formatAvailableFields } from '@utils/MetadataUtils';
import PopulationsDroppableArea from './PopulationsDroppableArea';
import { useElementSize } from '@mantine/hooks';
import { useCurrentProject } from '@hooks/useCurrentProject';
import { InlineEditableText } from '@components/InlineEditableText';
import Selecto, { OnSelect } from 'react-selecto';
import { RenamePopulationsModal } from './RenamePopulationsModal';
import { FixPopulationsModal } from './FixPopulationsModal';
import Fuse from 'fuse.js';
import { VirtualItem } from '@tanstack/react-virtual';
import VirtualizedList from '@components/VirtualizedList';

const FuseOptions = {
  includeScore: true,
  includeMatches: true,
  minMatchCharLength: 2,
  threshold: 0.2,
  keys: ['title'],
};

const PopulationsColumn: FC = () => {
  const populations = useAnalysisEditionStore((s) => s.populations);
  const setPopulations = useAnalysisEditionStore((s) => s.setPopulations);
  const analysisType = useAnalysisEditionStore((s) => s.analysisType);
  const dragging = useAnalysisEditionStore((s) => s.dragging);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [populationToEdit, setPopulationToEdit] =
    useState<Population>(undefined);
  const { ref, width } = useElementSize();
  const selectoRef = useRef<Selecto>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const scrollerRef = useRef<HTMLDivElement>(null);

  const SearchIcon =
    searchQuery === '' ? (
      <IconSearch size={14} color="lightgray" />
    ) : (
      <ActionIcon variant="transparent" onClick={() => setSearchQuery('')}>
        <IconX size={14} color="gray" />
      </ActionIcon>
    );
  const [selectedPopulations, setSelectedPopulations] = useState<string[]>([]);
  const [lastAdded, setLastAdded] = useState<string | null>(null);
  const [showRenameModal, setShowRenameModal] = useState(false);
  const [fuse, setFuse] = useState<Fuse<Population>>(
    new Fuse(populations, FuseOptions)
  );
  const populationsToShow = useMemo(
    () =>
      searchQuery ? fuse.search(searchQuery).map((d) => d.item) : populations,
    [fuse, populations, searchQuery]
  );

  // State to track if the list is active
  const [isActive, setIsActive] = useState(false);

  // Function to activate the list
  const activateList = () => setIsActive(true);

  // Function to deactivate the list
  const deactivateList = () => setIsActive(false);

  useEffect(() => setFuse(new Fuse(populations, FuseOptions)), [populations]);

  const handleDelete = useCallback(
    (id: string) => {
      setPopulations(populations.filter((p) => p.id !== id));
    },
    [populations, setPopulations]
  );

  const handleOrderChange = useCallback((id: string) => {

    // Reverse the order of the populations...
    const newPopulations = [...populations].reverse();
    const index = newPopulations.findIndex((p) => p.id === id);
    const population = newPopulations[index];
    newPopulations.splice(index, 1);
    newPopulations.unshift(population);
    setPopulations(newPopulations);

  }, [populations, setPopulations]);

  const handleEdit = useCallback(
    (id: string) => {
      setPopulationToEdit(populations.find((p) => p.id === id));
    },
    [populations, setPopulations]
  );

  const handleDeleteSelected = useCallback(() => {
    setPopulations(
      populations.filter((p) => !selectedPopulations.includes(p.id))
    );
    setSelectedPopulations([]);
  }, [populations, setPopulations, selectedPopulations]);

  const handleSelectAll = useCallback(() => {
    setSelectedPopulations(populationsToShow.map((p) => p.id));
    if (selectoRef.current) {
      const elements = populationsToShow.map(({ id }) =>
        document.getElementById(id)
      );
      selectoRef.current.setSelectedTargets(elements);
    }
  }, [populationsToShow, setPopulations, selectoRef.current]);

  const handleEditTitle = useCallback(
    (population: Population, title: string) => {
      setPopulations(
        populations.map((p) => {
          if (p.id === population.id) {
            return { ...population, title };
          } else {
            return p;
          }
        })
      );
    },
    [populations, setPopulations]
  );

  // Add a method to call handleSelectAll when the user pressed CMd+ A (meta key, so it works on mac and windows)
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (isActive && e.metaKey && e.key === 'a') {
        e.preventDefault();
        handleSelectAll();
      } else if (isActive && e.metaKey && e.key === 'Backspace') {
        e.preventDefault();
        handleDeleteSelected();
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [isActive, handleSelectAll, handleDeleteSelected]);

  // Effect to add and remove click event listeners
  useEffect(() => {
    // Add click event listener to document to track clicks outside the list
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        deactivateList();
      }
    };

    document.addEventListener('click', handleClickOutside, true);

    // Cleanup
    return () =>
      document.removeEventListener('click', handleClickOutside, true);
  }, []);

  const toggleItems = useCallback(
    (selectedValues: string[], idFrom: string, idTo: string): string[] => {
      let indexFrom = populationsToShow.findIndex((i) => i.id === idFrom);
      let indexTo = populationsToShow.findIndex((i) => i.id === idTo);

      if (indexFrom !== -1 && indexTo !== -1) {
        if (indexTo < indexFrom) {
          const aux = indexTo;
          indexTo = indexFrom;
          indexFrom = aux;
        }

        for (var i = indexFrom; i <= indexTo; i++) {
          if (!selectedValues.includes(populationsToShow[i].id)) {
            selectedValues.push(populationsToShow[i].id);
          }
        }
        return selectedValues;
      }
      return [];
    },
    [populationsToShow]
  );

  const onSelect = useCallback(
    (e: OnSelect) => {
      let toAdd = [];
      if (e.inputEvent.shiftKey && lastAdded !== null) {
        if (e.added.length > 0) {
          toAdd = toggleItems(
            e.selected.map((el) => el.id),
            lastAdded,
            e.added[e.added.length - 1].id
          );
        }
      } else if (e.added.length > 0) {
        setLastAdded(e.added[e.added.length - 1].id);
      } else {
        if (e.selected.length === 1) {
          setLastAdded(e.selected[0].id);
        } else {
          setLastAdded(null);
        }
      }

      toAdd = toAdd.filter((id) => !e.selected.map((e) => e.id).includes(id));
      setSelectedPopulations([...e.selected.map((e) => e.id), ...toAdd]);
    },
    [toggleItems, lastAdded]
  );

  return (
    <>
      <FixPopulationsModal />
      <PopulationModal
        opened={populationToEdit !== undefined}
        onClose={() => setPopulationToEdit(undefined)}
        population={populationToEdit}
      />
      <RenamePopulationsModal
        opened={showRenameModal}
        onClose={() => setShowRenameModal(false)}
        selectedPopulations={selectedPopulations}
      />
      <Stack style={{ flexGrow: 1 }}>
        <Text style={{ minHeight: 50 }}>
          {analysisType !== AnalysisType.DRIVERS_OF_OUTCOME
            ? '3. Review or edit your populations before confirm'
            : '3. Review or edit your population before confirm'}
          {
            analysisType === AnalysisType.PROGRESS_OVER_TIME ? (
              <p style={{fontSize:"12px",color:"grey"}}>Add the later population first, and the earlier population second</p>
            ) : null
          }
        </Text>
        {analysisType !== AnalysisType.DRIVERS_OF_OUTCOME && (
          <TextInput
            size="xs"
            placeholder="Search populations..."
            rightSection={SearchIcon}
            onChange={(e) => setSearchQuery(e.currentTarget.value)}
            value={searchQuery}
          />
        )}

        <Stack
          ref={ref}
          onClick={activateList}
          style={
            analysisType !== AnalysisType.DRIVERS_OF_OUTCOME
              ? { position: 'relative', flexGrow: 1 }
              : { position: 'relative', height: 250 }
          }
        >
          <PopulationsDroppableArea />
          {dragging !== 'row' && (
            <Card
              withBorder
              style={{
                display: 'flex',
                position: 'relative',
                flexDirection: 'column',
                flexGrow: 1,
              }}
            >
              {populationsToShow.length === 0 && searchQuery && (
                <Stack align="center" mt="xl" spacing="xs">
                  <Text color="grey">No data</Text>
                  <Anchor c="red" onClick={() => setSearchQuery('')}>
                    Remove filters
                  </Anchor>
                </Stack>
              )}

              {populationsToShow.length > 0 && (
                <Selecto
                  ref={selectoRef}
                  container={containerRef.current}
                  dragContainer={containerRef.current}
                  selectableTargets={['.population-target']}
                  onScroll={({ direction }) => {
                    scrollerRef.current!.scrollBy(
                      direction[0] * 10,
                      direction[1] * 10
                    );
                  }}
                  scrollOptions={{
                    container: scrollerRef,
                    getScrollPosition: () => {
                      return [
                        scrollerRef.current!.scrollLeft,
                        scrollerRef.current!.scrollTop,
                      ];
                    },
                    throttleTime: 30,
                    threshold: 0,
                  }}
                  hitRate={1}
                  selectByClick={true}
                  selectFromInside={true}
                  toggleContinueSelect={[['meta'], ['ctrl']]}
                  onSelect={onSelect}
                />
              )}

              <VirtualizedList
                ref={scrollerRef}
                data={populationsToShow}
                itemHeight={27.7}
                spacing={10}
                style={{ position: 'absolute', inset: 0, overflowX: 'scroll' }}
              >
                {(vi: VirtualItem) => {
                  console.log(vi)
                  return <PopulationItem
                    population={populationsToShow[vi.index]}
                    onEdit={handleEdit}
                    onDelete={handleDelete}
                    handleEditTitle={handleEditTitle}
                    selectedPopulations={selectedPopulations}
                    order={vi.index}
                    onChangeOrder={handleOrderChange}
                  />
                }}
              </VirtualizedList>
            </Card>
          )}
        </Stack>
        {selectedPopulations.length === 0 && (
          <Anchor size="sm" onClick={handleSelectAll}>{`Select all`}</Anchor>
        )}
        {selectedPopulations.length > 0 && (
          <Flex gap={'xs'}>
            <Text size="sm">{`${selectedPopulations.length} population${selectedPopulations.length > 1 ? 's' : ''
              }:`}</Text>
            <Anchor
              c="red"
              size="sm"
              onClick={handleDeleteSelected}
            >{`Delete`}</Anchor>
            <Anchor
              size="sm"
              onClick={() => setShowRenameModal(true)}
            >{`Rename`}</Anchor>
          </Flex>
        )}
      </Stack>
    </>
  );
};

interface PopulationItemProps {
  population: Population;
  onEdit: (id: string) => void;
  onDelete: (id: string) => void;
  handleEditTitle(population: Population, title: string);
  onChangeOrder?: (id: string) => void;
  selectedPopulations: string[];
  order?: number;
}

const PopulationItem: FC<PopulationItemProps> = ({
  population,
  onEdit,
  onDelete,
  handleEditTitle,
  selectedPopulations,
  order,
  onChangeOrder
}) => {

  const isSelected = selectedPopulations.includes(population.id);
  const isFirst = order === 0 ? true : false;

  return (
    <div style={{ minHeight: 37.7, margin: 10 }}>
      <Card
        variant="light"
        bg={isSelected ? 'blue' : '#e7f5ff'}
        id={population.id}
        className="population-target"
        pl="xs"
        pr={5}
        py={2}
        radius="sm"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          cursor: 'pointer',
          height: 27.7,
        }}
      >
        <Flex style={{ width: 'calc(100% - 25px)' }}>

          <Tooltip label="Click to change order">
            <ActionIcon radius={"xl"} size="xs" color="light" variant='default' onClick={() => onChangeOrder(population.id)}>
              {isFirst ? "1" : "2"}
            </ActionIcon>
          </Tooltip>

          <InlineEditableText
            textStyle={{
              color: isSelected ? 'white' : '#228be6',
              fontSize: '0.875rem',
              lineHeight: 1.55,
              fontWeight: 700,
            }}
            inputStyle={{ minHeight: 0 }}
            inputRootStyle={{ border: '1px solid white' }}
            actionIconStyle={{
              color: isSelected ? 'white' : '#228be6',
              '&:hover': { background: '#e7f5ff', color: '#228be6' },
            }}
            value={population.title}
            onChange={(value) => handleEditTitle(population, value)}
            size="xs"
          />
        </Flex>
        <MantineProvider
          theme={{
            components: {
              ActionIcon: {
                styles: {
                  root: {
                    color: isSelected ? 'white' : '#228be6',
                    '&:hover': { background: '#e7f5ff', color: '#228be6' },
                  },
                },
              },
            },
          }}
        >
          <Menu shadow="md" width={200} withinPortal withArrow>
            <Menu.Target>
              <ActionIcon size="xs">
                <IconDotsVertical size="1.125rem" />
              </ActionIcon>
            </Menu.Target>
            <Menu.Dropdown>
              <Menu.Label>{`Population: ${population.title}`}</Menu.Label>
              <Menu.Item
                icon={<IconEdit size="1.125rem" />}
                onClick={() => onEdit(population.id)}
              >
                Edit definition
              </Menu.Item>
              <Menu.Item
                icon={<IconTrash size="1.125rem" />}
                c="red"
                onClick={() => onDelete(population.id)}
              >
                Delete
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
        </MantineProvider>
      </Card>
    </div>
  );
};

interface PopulationModalProps {
  opened: boolean;
  onClose: () => void;
  population: Population;
}

const PopulationModal: FC<PopulationModalProps> = ({
  opened,
  onClose,
  population,
}) => {
  const project = useCurrentProject();
  const { data: datasetsMetadata } = useProjectDatasets(project);
  const dataset = useAnalysisEditionStore((s) => s.dataset);
  const populations = useAnalysisEditionStore((s) => s.populations);
  const setPopulations = useAnalysisEditionStore((s) => s.setPopulations);
  const availableFields = useMemo(
    () => (datasetsMetadata ? formatAvailableFields(datasetsMetadata) : {}),
    [datasetsMetadata]
  );

  if (!population || !datasetsMetadata) {
    return null;
  }

  return (
    <Modal
      size="xl"
      opened={opened}
      onClose={onClose}
      title={<Title size="h3">Population Definition</Title>}
    >
      <FocalPopulationView
        existingPopulations={populations.map((population) => population.title)}
        datasets={datasetsMetadata[dataset]}
        existingDefinition={population.definition}
        existingName={population.title}
        datasetMetadata={datasetsMetadata}
        availableFields={availableFields}
        isExistingPopulation={true}
        onComplete={(title, definition) => {
          setPopulations(
            populations.map((p) => {
              if (p.id === population.id) {
                return { ...population, title, definition };
              } else {
                return p;
              }
            })
          );
          onClose();
        }}
        onClose={onClose}
      />
    </Modal>
  );
};

export default PopulationsColumn;
