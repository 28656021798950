import ProjectModel from '@models/ProjectModel';
import ProjectAPI, {
  ProjectAnalysisDataType,
  ProjectMetricType,
} from '@apis/ProjectAPI';
import React, { useEffect, useMemo, useState } from 'react';

import {
  Stack,
  Title,
  Text,
  SelectItem,
  Select,
  Group,
  Button,
  Progress,
  createStyles,
  Alert,
  Modal,
  Checkbox,
  Divider,
  TextInput,
} from '@mantine/core';
import { MultiSelectCheckbox } from './MultiSelectCheckbox';
import { BenchmarkDataPoint } from './charts/datamodels/BenchmarkDataPoint';
import APIErrorMessage from './APIErrorMessage';
import ResourceAPI from '@apis/ResourceAPI';
import CRN from '@models/Crn';
import { ResourceMetadataModel } from '@models/ResourceModel';
import {
  AnalysisStatus,
  AnalysisType,
  IProjectAnalysisModel,
} from '@models/ProjectAnalysisModel/ProjectAnalysisModelBase';

import {
  SelectAnalysisItemProps,
  SelectAnalysisItem,
} from './SelectAnalysisItem';

import {
  userFriendlyThemeName,
  userFriendlyTopicName,
} from '@utils/TopicUtils';
import { InternalBenchmarkAnalysisModel } from '@models/ProjectAnalysisModel/InternalBenchmarkAnalysisModel';
import APIRequesterBase from '@apis/APIBase';
import { QuoteReaderDemographicSelect } from './QuoteReaderProfileComponent';
import DatasetAPI from '@apis/DatasetAPI';
import { useOverTimeAnalysisData } from './OverTimeAnalysisData';
import { Parser } from '@json2csv/plainjs';
import { useCurrentProject } from '@hooks/useCurrentProject';
import { useProjectTopics } from '@apis/hooks/useProjectTopics';
interface IProjectDownloadModalProps {
  project: ProjectModel;
  opened: boolean;

  defaultSelectedAnalysis?: IProjectAnalysisModel;
  defaultSelectedDataCategory?: DataCategory;
  defaultFocalTopic?: string;

  defaultQuoteTopic?: string;
  defaultQuoteCoMentionTopic?: string;
  defaultQuoteCoMentionType?: ProjectMetricType;
  defaultQuoteSentiment?: string[];

  onCancel: () => void;
}

export enum DataCategory {
  KeyDrivers = 'KeyDrivers',
  Base = 'Base',
  CoMention = 'CoMention',
  DirectComparisonScores = 'DirectComparisonScores',
  TimeComparisonScores = 'TimeComparisonScores',
  Quotes = 'Quotes',
  OverTimeMetrics = "OverTimeMetrics"
}

const ALLOWED_ANALYSIS_TYPE_DATA_CATEGORIES = {
  [AnalysisType.DRIVERS_OF_OUTCOME]: [
    {
      label: 'Basic Sentiment & Incidence Metrics',
      value: DataCategory.Base,
    },
    {
      label: 'Key Drivers',
      description: 'See drivers of your provided outcome',
      value: DataCategory.KeyDrivers,
    },

    {
      label: 'Co-Mentions',
      value: DataCategory.CoMention,
    },
  ],

  [AnalysisType.INTERNAL_BENCHMARK]: [
    {
      label: 'Basic Sentiment & Incidence Metrics',
      value: DataCategory.Base,
    },
    {
      label: 'Co-Mentions',
      value: DataCategory.CoMention,
    },
  ],
  [AnalysisType.PROGRESS_OVER_TIME]: [
    {
      label: "Over Time Metrics",
      value: DataCategory.OverTimeMetrics,
    }
  ]
};

export function getKeysWithValueFromMap(map: Map<string, any>, value: string) {
  const keys = [];
  for (const [key, values] of map.entries()) {
    if (values.includes(value)) {
      keys.push(key);
    }
  }
  return keys;
}

export const ProjectDownloadDataModal: React.FC<IProjectDownloadModalProps> = ({
  project,
  onCancel,
  opened,
  defaultFocalTopic,
  defaultSelectedAnalysis,
  defaultSelectedDataCategory,
  defaultQuoteTopic,
  defaultQuoteCoMentionTopic,
  defaultQuoteCoMentionType,
  defaultQuoteSentiment,
}) => {
  const [
    allowedAnalysisTypeDataCategories,
    setAllowedAnalysisTypeDataCategories,
  ] = useState<any>(ALLOWED_ANALYSIS_TYPE_DATA_CATEGORIES);
  const [datasetMetadata, setDatasetMetadata] = useState<Map<string, any>>(
    new Map<string, any>()
  );


  const projectData = useProjectTopics(project);
  const themeTopicMapping = projectData?.data?.topicThemeMapping || new Map<string, string[]>();
  const generateOverTimeData = useOverTimeAnalysisData()




  useEffect(() => {
    (async () => {
      const currentOrgId = await APIRequesterBase.getCurrentOrganizationId();

      let _allowedAnalysisTypeDataCategories = {
        ...ALLOWED_ANALYSIS_TYPE_DATA_CATEGORIES,
      };

      // Only add if Quotes doesn't already exist...
      if (
        _allowedAnalysisTypeDataCategories[
          AnalysisType.INTERNAL_BENCHMARK
        ].find((obj: any) => obj.value === DataCategory.Quotes) === undefined
      ) {
        _allowedAnalysisTypeDataCategories[
          AnalysisType.INTERNAL_BENCHMARK
        ].push({
          label: 'Quotes',
          value: DataCategory.Quotes,
        });
      }

      // Only add if Quotes doesn't already exist...
      if (
        _allowedAnalysisTypeDataCategories[
          AnalysisType.DRIVERS_OF_OUTCOME
        ].find((obj: any) => obj.value === DataCategory.Quotes) === undefined
      ) {
        _allowedAnalysisTypeDataCategories[
          AnalysisType.DRIVERS_OF_OUTCOME
        ].push({
          label: 'Quotes',
          value: DataCategory.Quotes,
        });
      }

      setAllowedAnalysisTypeDataCategories(_allowedAnalysisTypeDataCategories);
    })();
  }, [project]);

  const analysesOptions: SelectAnalysisItemProps[] = useMemo(() => {
    if (project) {
      return project.analyses.map((analysis: IProjectAnalysisModel) => {
        const result: SelectAnalysisItemProps = {
          value: analysis.id,
          label: analysis.name,
          analysisType: analysis.type,
          status: analysis.status,
          disabled:
            analysis.status !== AnalysisStatus.READY &&
            analysis.status !== AnalysisStatus.READY_WITH_RESTRICTIONS,
        };
        return result;
      });
    }
    return [];
  }, [project]);

  const getListOfTopics = async (): Promise<Set<string>> => {
    var topics: Set<string> = new Set();

    // Go through the datasets and get the resource metadata...

    for (const [datasetAlias, datasetInfo] of Object.entries(
      project.datasets
    )) {
      const longCSICRN = datasetInfo.LongCSICRN;

      try {
        const resourceMetadata: ResourceMetadataModel =
          await ResourceAPI.getResourceMetadataFromCrn(
            CRN.fromString(longCSICRN)
          );

        // Go through the resource metadata and get all of the topics...

        const resourceTopics: string[] =
          resourceMetadata.content.properties.ImmutableAttributes.Columns.find(
            (obj: any) => obj.topic
          )?.topic.Values?.sort();

        if (resourceTopics) {
          // Extend the topics set with the resource topics...
          topics = new Set([...topics, ...resourceTopics]);
        }
      } catch (error) {
        console.log(error);
      }
    }

    return topics;
  };

  const getDatasetMetadata = async (
    datasetAlias: string
  ): Promise<Map<string, any>> => {
    return await DatasetAPI.getDatasetMetadataWithIds(
      project.organizationId,
      datasetAlias.slice(3)
    );
  };

  const getMetadataForProjectDatasets = async (): Promise<Map<string, any>> => {
    const datasets = project.datasets;
    const datasetIds = Object.keys(datasets);

    const datasetMetadata: Map<string, any> = new Map<string, any>();

    // Go through the datasets and get the resource metadata...
    const allData = await Promise.all(datasetIds.map(getDatasetMetadata));

    datasetIds.forEach((datasetId, index) => {
      datasetMetadata[datasetId] = allData[index];
    });

    return datasetMetadata;
  };

  useEffect(() => {
    getListOfTopics()
      .then((topics: Set<string>) => {
        const options: SelectItem[] = [...topics].map((topic) => {
          // Formatted name
          const formattedTopicName = topic.endsWith('_T')
            ? userFriendlyThemeName(topic)
            : userFriendlyTopicName(topic);

          return {
            label: formattedTopicName,
            value: topic,
          };
        });

        setAvailableTopicOptions(options);
      })
      .catch((error) => {
        console.log(error);
      });

    getMetadataForProjectDatasets().then((metadata: Map<string, any>) => {
      const datasetMetadataMapping = new Map<string, any>();

      for (const [datasetAlias, metadataObject] of Object.entries(metadata)) {
        datasetMetadataMapping.set(datasetAlias, metadataObject);
      }
      setDatasetMetadata(datasetMetadataMapping);
    });
  }, [project]);

  // MARK: - State management
  const [error, setError] = useState<JSX.Element | null>(null);
  const [availableTopicOptions, setAvailableTopicOptions] = useState<
    SelectItem[]
  >([]);
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const [focalTopic, setFocalTopic] = useState<string | null>(
    defaultFocalTopic || null
  );
  const [selectedAnalysisError, setSelectedAnalysisError] = useState<
    string | null
  >(null);
  const [focalTopicError, setFocalTopicError] = useState<string | null>(null);
  const [preferredFileName, setPreferredFileName] = useState<string | null>(null);
  const [hasChangedFileName, setHasChangedFileName] = useState<boolean>(false);
  const [selectedAnalysis, setSelectedAnalysis] =
    useState<IProjectAnalysisModel | null>(
      defaultSelectedAnalysis || project.analyses[0]
    );
  const [selectedDataCategory, setSelectedDataCategory] =
    useState<DataCategory>(defaultSelectedDataCategory || DataCategory.Base);

  // MARK: - Quotes state...
  const [quoteTopic, setQuoteTopic] = useState<string | null>(
    defaultQuoteTopic
  );
  const [quoteTopicError, setQuoteTopicError] = useState<string | null>(null);

  const [quoteShouldIncludeCoMention, setQuoteShouldIncludeCoMention] =
    useState<boolean>(defaultQuoteCoMentionTopic !== undefined || false);
  const [quoteCoMentionTopic, setQuoteCoMentionTopic] = useState<string | null>(
    defaultQuoteCoMentionTopic || null
  );
  const [quoteCoMentionTopicError, setQuoteCoMentionTopicError] = useState<
    string | null
  >(null);
  const [quoteCoMentionType, setQuoteCoMentionType] = useState<string | null>(
    defaultQuoteCoMentionType || null
  );

  const [quoteSentiment, setQuoteSentiment] = useState<string[] | null>(
    defaultQuoteSentiment || ['positive', 'negative', 'indeterminate']
  );
  const [quoteSentimentError, setQuoteSentimentError] = useState<string | null>(
    null
  );
  const [quoteDemographics, setQuoteDemographics] = useState<any | undefined>(
    undefined
  );

  const dataCategoryOptions: SelectItem[] = useMemo(() => {
    return (
      allowedAnalysisTypeDataCategories[
      selectedAnalysis?.type || AnalysisType.INTERNAL_BENCHMARK
      ] || []
    );
  }, [selectedAnalysis]);


  useEffect(() => {

    if (!hasChangedFileName) {

      var dataCategory = "Sentiment & Incidence Metrics";

      switch (selectedDataCategory) {
        case DataCategory.Base:
          dataCategory = "Sentiment & Incidence Metrics";
          break;
        case DataCategory.DirectComparisonScores:
          dataCategory = "Direct Comparison Scores";
          break;
        case DataCategory.TimeComparisonScores:
          dataCategory = "Time Comparison Scores";
          break;
        case DataCategory.KeyDrivers:
          dataCategory = "Key Drivers";
          break;
        case DataCategory.CoMention:
          dataCategory = "Co-Mentions";
          break;
        case DataCategory.Quotes:
          dataCategory = "Quotes";
          break;
        case DataCategory.OverTimeMetrics:
          dataCategory = "Progress Over Time Metrics";
          break;
      }

      var fileName = `${selectedAnalysis.name} - ${dataCategory}`;

      if (focalTopic !== null && selectedDataCategory === DataCategory.CoMention) {
        fileName = `${fileName} - ${focalTopic}`;
      }

      if (quoteTopic !== null && quoteTopic !== undefined && selectedDataCategory === DataCategory.Quotes) {
        fileName = `${fileName} - ${userFriendlyTopicName(quoteTopic)}`;
      }

      setPreferredFileName(fileName.slice(0, 200));
    }

  }, [selectedAnalysis, selectedDataCategory, focalTopic]);


  const handleOnDownload = () => {
    if (isDownloading) return;

    if (selectedAnalysis === null) {
      setSelectedAnalysisError('Please select an analysis');
      return;
    }

    // Go ahead and make a request to download the project data...

    setIsDownloading(true);
    setError(null);
    setSelectedAnalysisError(null);
    setFocalTopicError(null);

    // TODO: - Add different metric types...
    if (selectedDataCategory === DataCategory.OverTimeMetrics) {

      try {


        const dataFromOverTime = generateOverTimeData()

        let dataForExcel: Record<string, any>[] = [];
        for (let index = 0; index < dataFromOverTime.length; index++) {
          const point = dataFromOverTime[index];

          const keys = getKeysWithValueFromMap(themeTopicMapping, point.firstPopulationElement.topic)
         
          if (keys.length === 0) {
            dataForExcel.push({
              Topic: point.firstPopulationElement.topicUserFriendlyName,
              Topic_Raw: point.firstPopulationElement.topic,
              Theme: point.firstPopulationElement.topic,
              Is_Theme: point.firstPopulationElement.isTheme,
              [`${point.firstPopulationElement.populationName} Incidence Percent`]: point.firstPopulationElement.incidence,
              [`${point.firstPopulationElement.populationName} Sentiment Percent`]: point.firstPopulationElement.sentiment,
              [`${point.firstPopulationElement.populationName} Negative Incidence Percent`]: point.firstPopulationElement.negativeIncidence,
              [`${point.firstPopulationElement.populationName} Positive Incidence Percent`]: point.firstPopulationElement.positiveIncidence,
              [`${point.firstPopulationElement.populationName} Vopic Count`]: point.firstPopulationElement.vopicCount,
              [`${point.firstPopulationElement.populationName} Row Count`]: point.firstPopulationElement.rowCount,
              [`${point.secondPopulationElement.populationName} Incidence Percent`]: point.secondPopulationElement.incidence,
              [`${point.secondPopulationElement.populationName} Sentiment Percent`]: point.secondPopulationElement.sentiment,
              [`${point.secondPopulationElement.populationName} Negative Incidence Percent`]: point.secondPopulationElement.negativeIncidence,
              [`${point.secondPopulationElement.populationName} Positive Incidence Percent`]: point.secondPopulationElement.positiveIncidence,
              [`${point.secondPopulationElement.populationName} Vopic Count`]: point.secondPopulationElement.vopicCount,
              [`${point.secondPopulationElement.populationName} Row Count`]: point.secondPopulationElement.rowCount,
              [`${point.firstPopulationElement.populationName} VS ${point.secondPopulationElement.populationName} Progress`]: point.theCalculation
            })
          } else {
            keys.forEach((key) => {
              dataForExcel.push({
                Topic: point.firstPopulationElement.topicUserFriendlyName,
                Topic_Raw: point.firstPopulationElement.topic,
                Theme: point.firstPopulationElement.isTheme ? point.firstPopulationElement.topic : key,
                Is_Theme: point.firstPopulationElement.isTheme,
                [`${point.firstPopulationElement.populationName} Incidence Percent`]: point.firstPopulationElement.incidence,
                [`${point.firstPopulationElement.populationName} Sentiment Percent`]: point.firstPopulationElement.sentiment,
                [`${point.firstPopulationElement.populationName} Negative Incidence Percent`]: point.firstPopulationElement.negativeIncidence,
                [`${point.firstPopulationElement.populationName} Positive Incidence Percent`]: point.firstPopulationElement.positiveIncidence,
                [`${point.firstPopulationElement.populationName} Vopic Count`]: point.firstPopulationElement.vopicCount,
                [`${point.firstPopulationElement.populationName} Row Count`]: point.firstPopulationElement.rowCount,
                [`${point.secondPopulationElement.populationName} Incidence Percent`]: point.secondPopulationElement.incidence,
                [`${point.secondPopulationElement.populationName} Sentiment Percent`]: point.secondPopulationElement.sentiment,
                [`${point.secondPopulationElement.populationName} Negative Incidence Percent`]: point.secondPopulationElement.negativeIncidence,
                [`${point.secondPopulationElement.populationName} Positive Incidence Percent`]: point.secondPopulationElement.positiveIncidence,
                [`${point.secondPopulationElement.populationName} Vopic Count`]: point.secondPopulationElement.vopicCount,
                [`${point.secondPopulationElement.populationName} Row Count`]: point.secondPopulationElement.rowCount,
                [`${point.firstPopulationElement.populationName} VS ${point.secondPopulationElement.populationName} Progress`]: point.theCalculation
              })
            })
          }


        }

        

        const parser = new Parser();
        const csv = parser.parse(dataForExcel);

        const fileName = preferredFileName ? preferredFileName : `${project.title} Progress Over time Analysis.csv`
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = `${fileName}.csv`;
        link.click();

      } catch (error) {
        setIsDownloading(false);
        console.log("Error generating CSV")
      }

      setIsDownloading(false);
      return
    }

    if (
      selectedDataCategory === DataCategory.Base ||
      selectedDataCategory === DataCategory.DirectComparisonScores ||
      selectedDataCategory === DataCategory.TimeComparisonScores ||
      selectedDataCategory === DataCategory.KeyDrivers
    ) {
      ProjectAPI.getBulkAnalysisDataUrl(
        project.projectId,
        selectedAnalysis.id,
        selectedDataCategory,
        undefined,
        project.organizationId,
        preferredFileName ? preferredFileName : `${project.title} Benchmark Data`
      )
        .then((presignedUrl: string) => {
          const filename = preferredFileName ? preferredFileName : `${project.title} Benchmark Data.csv`;

          const link = document.createElement('a');
          link.href = presignedUrl;
          link.download = filename;
          link.style.display = 'none';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);

          onCancel();
          setIsDownloading(false);
        })
        .catch((error) => {
          // TODO: - Handle the error message...
          setError(<APIErrorMessage response={error} dismissable={false} />);
          setIsDownloading(false);
        });
    } else if (selectedDataCategory === DataCategory.CoMention) {
      if (focalTopic === null) {
        setFocalTopicError('Please select a focal topic');
        setIsDownloading(false);
        return;
      }

      ProjectAPI.getBulkAnalysisDataUrl(
        project.projectId,
        selectedAnalysis.id,
        selectedDataCategory,
        focalTopic,
        project.organizationId,
        preferredFileName ? preferredFileName : `${project.title} Benchmark Data`
      )
        .then((presignedUrl: string) => {
          const filename = preferredFileName ? `${preferredFileName}.csv` : `${selectedAnalysis.name} Benchmark Data.csv`;

          const link = document.createElement('a');
          link.href = presignedUrl;
          link.download = filename;
          link.style.display = 'none';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);

          onCancel();
          setIsDownloading(false);
        })
        .catch((error) => {
          setError(<APIErrorMessage response={error} dismissable={false} />);
          setIsDownloading(false);
        });
    } else if (selectedDataCategory === DataCategory.Quotes) {
      if (quoteTopic === null) {
        setQuoteTopicError('Please select a topic');
        setIsDownloading(false);
        return;
      }

      let sentiment = ['positive', 'negative', 'indeterminate'];
      let focalTopic = undefined;
      let mentionType: ProjectMetricType | undefined = undefined;

      if (quoteShouldIncludeCoMention) {
        if (quoteCoMentionTopic === undefined || quoteCoMentionTopic === null) {
          setQuoteCoMentionTopicError('Please select a co-occurring topic');
          setIsDownloading(false);
          return;
        } else {
          setQuoteCoMentionTopicError(null);
        }

        focalTopic = quoteCoMentionTopic.replace('_', ' ');
        mentionType =
          quoteCoMentionType === 'PositiveCoMention'
            ? ProjectMetricType.PositiveCoMention
            : ProjectMetricType.NegativeCoMention;
      } else {
        if (
          quoteSentiment === undefined ||
          quoteSentiment === null ||
          quoteSentiment.length === 0
        ) {
          setQuoteSentimentError(
            'Please select at least one value for sentiment'
          );
          setIsDownloading(false);
          return;
        } else {
          setQuoteSentimentError(null);
        }

        sentiment = quoteSentiment;
      }

      let requestedDemographics = undefined;

      if (quoteDemographics !== undefined) {
        requestedDemographics = quoteDemographics;
      }

      let elapsedTime = 0;
      const maxTime = 300000; // 5 minutes in milliseconds
      const intervalTime = 5000; // 5 seconds in milliseconds

      ProjectAPI.getBulkQuotesJobId(
        project.projectId,
        project.organizationId,
        selectedAnalysis.id,
        [quoteTopic, quoteTopic.replace('_', ' ')],
        sentiment,
        focalTopic,
        mentionType,
        requestedDemographics
      )
        .then((jobId: string) => {
          const interval = setInterval(() => {
            elapsedTime += intervalTime;

            if (elapsedTime > maxTime) {
              clearInterval(interval);
              // Handle timeout logic here if needed.
              setIsDownloading(false);
              return;
            }

            ProjectAPI.getBulkQuotesDataUrl(
              project.projectId,
              project.organizationId,
              jobId
            )
              .then((presignedUrl: string) => {
                if (presignedUrl !== undefined) {
                  const filename = preferredFileName ? preferredFileName : `${project.title} Quotes`;

                  const link = document.createElement('a');
                  link.href = presignedUrl;
                  link.download = filename;
                  link.style.display = 'none';
                  document.body.appendChild(link);
                  link.click();
                  document.body.removeChild(link);

                  clearInterval(interval);
                  setIsDownloading(false);
                  onCancel();
                }
              })
              .catch((error) => {
                clearInterval(interval);
                setError(
                  <APIErrorMessage response={error} dismissable={false} />
                );
                setIsDownloading(false);
              });
          }, intervalTime);
        })
        .catch((error) => {
          setError(<APIErrorMessage response={error} dismissable={false} />);
          setIsDownloading(false);
        });
    }
  };

  const onAnalysisChange = (value: string) => {
    // Find the analysis with the given ID...
    const analysis = project.analyses.find(
      (analysis: IProjectAnalysisModel) => analysis.id === value
    );

    if (analysis.type !== selectedAnalysis?.type) {
      // Check to see if the current data category is allowed:

      const allowedDataCategories =
        allowedAnalysisTypeDataCategories[analysis.type];

      if (
        allowedDataCategories.find(
          (obj: any) => obj.value === selectedDataCategory
        ) === undefined
      ) {
        setSelectedDataCategory(allowedDataCategories[0].value);
      }
    }

    setSelectedAnalysis(analysis);
  };

  const onDataCategoryChange = (value: string) => {
    // Convert the string to a DataCategory...
    setSelectedDataCategory(value as DataCategory);
  };

  return (
    <>
      <Modal
        opened={opened}
        onClose={() => {
          onCancel();
        }}
        withCloseButton={false}
        size="xl"
      >
        <Stack spacing={'xs'}>
          <Title size="h2">Download Project Data</Title>
          <Text size="sm">
            Use the raw project data in either a spreadsheet application or
            another BI analytics tool, such as Tableau or Power BI.
          </Text>
        </Stack>
        <br />
        <Stack>
          <Select
            data={analysesOptions}
            searchable
            label="Analysis"
            error={selectedAnalysisError}
            nothingFound="No analyses found"
            description="Select the analysis you'd like to download data from"
            required
            defaultValue={selectedAnalysis.id}
            itemComponent={SelectAnalysisItem}
            onChange={onAnalysisChange}
          />

          <Select
            data={dataCategoryOptions}
            searchable
            label="Data Category"
            description="Select the category of data you'd like to download from your analysis"
            required
            defaultValue={selectedDataCategory}
            onChange={onDataCategoryChange}
          />
          {selectedDataCategory === DataCategory.CoMention ? (
            <>
              <Select
                data={availableTopicOptions}
                label="Focal Topic"
                error={focalTopicError}
                description={`Select a focal topic to download co-mentions from`}
                required
                searchable
                defaultValue={focalTopic}
                onChange={(value: string) => setFocalTopic(value)}
              />
            </>
          ) : null}

          {selectedDataCategory === DataCategory.Quotes ? (
            <>
              <Select
                data={availableTopicOptions}
                label="Topic"
                error={quoteTopicError}
                description={`Select a focal topic to download quotes from`}
                required
                searchable
                defaultValue={quoteTopic}
                onChange={(value: string) => setQuoteTopic(value)}
              />
              <Checkbox
                size="xs"
                label="Include quotes which co-occur with another topic"
                onChange={(event: any) =>
                  setQuoteShouldIncludeCoMention(event.target.checked)
                }
              />
              {quoteShouldIncludeCoMention && (
                <Group spacing={'xs'}>
                  <Select
                    clearable
                    data={availableTopicOptions}
                    label="Co-Occurring Topic"
                    error={quoteCoMentionTopicError}
                    required
                    description={`Select another topic which co-occurs with the focal topic`}
                    searchable
                    defaultValue={quoteCoMentionTopic}
                    onChange={(value: string) => setQuoteCoMentionTopic(value)}
                  />
                  {quoteCoMentionTopic !== undefined && (
                    <>
                      <Select
                        data={[
                          {
                            label: 'Positively Co-Occurring',
                            value: 'PositiveCoMention',
                          },
                          {
                            label: 'Negatively Co-Occurring',
                            value: 'NegativeCoMention',
                          },
                        ]}
                        label="Co-Occurring Type"
                        description={`Select how the co-occurring topic is related to the focal topic`}
                        searchable
                        defaultValue={quoteCoMentionType || 'NegativeCoMention'}
                        onChange={(value: string) =>
                          setQuoteCoMentionType(value)
                        }
                      />
                    </>
                  )}
                </Group>
              )}
              {!quoteShouldIncludeCoMention && (
                <MultiSelectCheckbox
                  error={quoteSentimentError}
                  data={[
                    { label: 'Positive', value: 'positive' },
                    { label: 'Negative', value: 'negative' },
                    { label: 'Indeterminate', value: 'indeterminate' },
                  ]}
                  label="Sentiment"
                  description={`Select the sentiment of quotes you'd like to download`}
                  required
                  searchable
                  defaultValue={
                    quoteSentiment || ['positive', 'negative', 'indeterminate']
                  }
                  onChange={(value: string[]) => setQuoteSentiment(value)}
                />
              )}
              <Divider />
              <Stack spacing={'xxs'}>
                <Title order={6}>Respondent Profile</Title>
                <Text size="xs" color="dimmed">
                  Optionally include up to 4 demographic fields associated to
                  the respondent to include in your export
                </Text>
              </Stack>
              <QuoteReaderDemographicSelect
                metadata={datasetMetadata}
                selectedDemographics={quoteDemographics}
                onDemographicsChange={setQuoteDemographics}
              />
            </>
          ) : null}
          <TextInput
            label="File Name"
            maxLength={200}
            value={preferredFileName}
            onChange={(event: any) => {
              setPreferredFileName(event.target.value)
              setHasChangedFileName(true);
            }}
          />
        </Stack>
        <br />
        <br />
        {error !== null && error ? (
          <>
            <Group position="right">{error}</Group>
            <br />
          </>
        ) : null}
        <Group position="right">
          <Button variant="subtle" color="dark" onClick={onCancel}>
            Cancel
          </Button>
          <Button loading={isDownloading} onClick={handleOnDownload}>
            {isDownloading ? 'Preparing...' : 'Download'}
          </Button>
        </Group>
      </Modal>
    </>
  );
};
